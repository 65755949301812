html {
	position: relative;
	min-height: 100%;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	font-weight: 400;
	background-color: #f6f6f6;
	color: #5e5e5e;
}


* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #a0a0a0;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #a0a0a0;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: #a0a0a0;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #a0a0a0;
}

/*
*
*
* REACT-TABLE-STYLES
*
*/

.ReactTable {
	border-radius: 5px;
	overflow: hidden;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-thead.-headerGroups {
	background-color: #434192;
}

.ReactTable .rt-thead.-header .rt-th,
.ReactTable .rt-thead.-headerGroups .rt-th {
	color: white;
	padding: 10px 5px;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 13px;
		padding: 8px 5px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 12px;
	}
}
/* @media (max-width: 650px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		padding: 5px 5px;
	}
} */

.ReactTable .rt-thead.-filter .rt-th {
	color: white;
	padding: 10px 5px;
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 8px 5px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 5px;
	}
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	-webkit-box-shadow: inset 0 3px 0 0 white;
	box-shadow: inset 0 3px 0 0 white;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	-webkit-box-shadow: inset 0 -3px 0 0 white;
	box-shadow: inset 0 -3px 0 0 white;
}

.ReactTable .rt-thead .rt-th input[type='text'] {
	background-image: url(./images/searchIcon.png);
	background-position: calc(100% - 3px) center;
	background-repeat: no-repeat;
	background-size: auto calc(100% - 6px);
	padding: 5px;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	font-size: 16px;

	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th input[type='text']:hover {
	border-color: #434192;
	box-shadow: 0 0 0 1px #434192 inset;
}

.ReactTable .rt-tbody .rt-td {
	align-self: center;
	padding: 10px 5px;
	font-size: 14px;
	min-height: 30px;
}
@media (max-width: 800px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 8px 5px;
		font-size: 13px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 5px;
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th.right {
	text-align: right;
}

.ReactTable .rt-tbody .rt-td.actionCell {
	padding: 5px;
}
.ReactTable .rt-tbody .rt-td.noPadding {
	padding: 0px;
}
.ReactTable .rt-tbody .rt-td.center {
	text-align: center;
}
.ReactTable .rt-tbody .rt-td.capitalize {
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.center-capitalize {
	text-align: center;
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.right {
	text-align: right;
}

/* React Big Calendar*/
/* .rbc-calendar {
	height: 800px !important;
	margin-bottom: 50px;
} */
